import React from 'react';
import advantagesData from '../../../data/advantages.json';
import './style.scss';
import terminalSrc from '../../../assets/images/advantages-terminal.png';

const Advantages = () => {
    return (
        <div className='home_block advantages_block'>
            <h1 className='block_title'>Why choose Us?</h1>
            <div className='advantages_main'>
                <div className='left_part'>
                    {advantagesData.slice(0, advantagesData.length / 2).map((item, idx) => (
                        <div key={idx} className='advantage_nth'>
                            <span className='advantage_number'>{item.nth}</span>
                            <h4 className='home_desc advantage_desc'>{item.description}</h4>
                        </div>
                    ))}
                </div>
                <div className='img_part'>
                    <img src={terminalSrc} alt='Terminal'/>
                    <div className='circle'></div>
                </div>
                <div className='right_part'>
                    {advantagesData.slice(advantagesData.length / 2).map((item, idx) => (
                        <div key={idx} className='advantage_nth'>
                            <span className='advantage_number'>{item.nth}</span>
                            <h4 className='home_desc advantage_desc'>{item.description}</h4>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default Advantages;