import React from 'react';
import './style.scss';
import Slider from "react-slick";
import partnersData from '../../../data/partners.json';

const Partners = () => {
    const settings = {
        infinite: true,
        speed: 500,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1
    };

    return (
        <div className='home_block partners_block'>
            <h1 className='block_title'>Our Partners</h1>
            <Slider {...settings}>
                {partnersData.map((item, idx) => (
                    <div key={idx} className='partner_nth'>
                        <img src={require('../../../assets/images' + item.src)} alt='Partner' />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Partners;
