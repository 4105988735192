import './App.scss';
import {Route, Routes} from "react-router-dom";
import Header from "./components/layout/header";
import Home from "./pages/home";
import Projects from "./pages/projects";
import ContactUs from "./pages/contact-us";
import {useEffect} from "react";
import Footer from "./components/layout/footer";
import './i18n';
function App() {

    useEffect(() => {
        !localStorage.getItem('lang') && localStorage.setItem('lang', 'en')
    }, [])

  return (
    <div className="App">
        <Header />
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='*' element={<Home />} />
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
