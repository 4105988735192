import React from 'react';
import './style.scss';
import BannerSlider from "../../components/home/bannerSlider";
import Services from "../../components/home/services";
import Partners from "../../components/home/partners";
import Advantages from "../../components/home/advantages";
import Latest from "../../components/home/latests";

const Home = () => {
    return (
        <div className='home_page'>
            <BannerSlider />
            <Services />
            <Partners />
            <Advantages />
            <Latest />
        </div>
    );
};

export default Home;
