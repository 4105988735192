import React, {useState} from 'react';
import Slider from "react-slick";
import latestData from '../../../data/latest.json';
import './style.scss';
import useWindowSize from "../../../helper/useWindowSize";
import arrowDown from '../../../assets/icons/arrow-down.svg';

const Latest = () => {
    const isMobile = useWindowSize();
    const [show, setShow] = useState(false);
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        speed: 500,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    return (
        <div className='home_block latest_block'>
            <h1 className='block_title'>Latest from E-Soft</h1>
            {isMobile ? (
                    <div className='mobile_latest'>
                        {latestData.slice(0, show ? -1 : 2).map((item, idx) => (
                            <div key={idx} className='news_nth' key={idx}>
                                <div className='news_image'>
                                    <img src={require('../../../assets/images' + item.src)} alt='News'/>
                                </div>
                                <div className='news_info'>
                                    <h1 className='news_title'>{item.title}<b>{item.highlighted}</b></h1>
                                    <h3 className='news_desc'>{item.description}</h3>
                                </div>
                            </div>
                        ))}
                        <div className={`show_more ${show ? 'less' : ''}`} onClick={() => setShow(!show)}>
                            <span>{show ? 'Less' : 'More'}</span>
                            <img src={arrowDown} alt='Arrow'/>
                        </div>
                    </div>
                ) :

                (<Slider {...settings}>
                    {latestData.map((item, idx) => (
                        <div key={idx} className='news_nth'>
                            <div className='news_image'>
                                <img src={require('../../../assets/images' + item.src)} alt='News'/>
                            </div>
                            <div className='news_info'>
                                <h1 className='news_title'>{item.title}<b>{item.highlighted}</b></h1>
                                <h3 className='news_desc'>{item.description}</h3>
                            </div>
                        </div>
                    ))}
                </Slider>)}
        </div>
    );
};

export default Latest;