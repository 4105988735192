import React from 'react';
import bannerItemsData from '../../../data/bannerItems.json';
import Slider from "react-slick";
import './style.scss';

const BannerSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        autoplaySpeed: 6000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                }
            }
        ]
    };
    return (
        <div className='banner_block'>
            <Slider {...settings}>
                {bannerItemsData.map((item, idx) => (
                    <div key={idx} className='banner_item'>
                        <div className='banner_info'>
                            <h1 className='item_title'>{item.title}</h1>
                            <h4 className='home_desc item_desc'>{item.description}</h4>
                            <form className='desktop_form'>
                                <input type='text' placeholder='Phone'/>
                                <button>Request call</button>
                            </form>

                        </div>
                        <img src={require('../../../assets/images' + item.src)} alt='Banner Item'
                             className='banner_img'/>
                        <form className='mobile_form'>
                            <input type='text' placeholder='Phone'/>
                            <button>Request call</button>
                        </form>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default BannerSlider;
