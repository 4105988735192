import React, {useEffect, useRef, useState} from 'react';
import flagItemsData from "../../data/flagItems.json";
import './style.scss';
import arrowDown from '../../assets/icons/arrow-down.svg';
import {useTranslation} from "react-i18next";

const SelectLanguage = () => {
    const myRef = useRef(null);
    const [open, setOpen] = useState(false);
    const selectedLang = flagItemsData.find(item => item.lang === localStorage.getItem('lang'))
    const [selected, setSelected] = useState(selectedLang || flagItemsData[0])
    const [t, i18n] = useTranslation();
    const handleSelect = (item) => {
        setOpen(false)
        setSelected(item)
        localStorage.setItem('lang', item.lang)
        i18n.changeLanguage(item.lang)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (myRef.current && !myRef.current.contains(event.target)) {
                setOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [myRef]);

    return (
        <div className="custom_select">
            <div
                className='custom_select_inner'
                onClick={() => setOpen(!open)}
            >
                <img src={require('../../assets/images' + selected.src)} alt='Language'/>
                <img src={arrowDown} alt='Arrow'/>
            </div>
            <div className={`custom_select_info ${open ? "open" : ""}`} ref={myRef}>
                {flagItemsData.map((item, idx) => (
                    <img key={idx} src={require('../../assets/images' + item.src)} alt='Language'
                         onClick={() => handleSelect(item)}/>
                ))}
            </div>
        </div>
    );
};

export default SelectLanguage;
