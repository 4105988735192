import React from 'react';
import {Link, useLocation} from "react-router-dom";
import LogoPayWay from "../../../assets/components/icons/LogoPayWay";
import './style.scss';
import menuItemsData from '../../../data/menuItems.json';
import SelectLanguage from "../../header/SelectLanguage";
import {useTranslation} from "react-i18next";

const Header = () => {
    const {pathname} = useLocation();
    const [t] = useTranslation();

    return (
        <header>
            <nav className='header_nav'>
                <div className="header_logo">
                    <Link to="/">
                        <LogoPayWay/>
                    </Link>
                </div>
                <div className='menu_items'>
                    {menuItemsData.map((item, idx) => (
                        <Link key={idx} to={item.slug}>
                            <span className={`nth_item ${item.slug === pathname ? 'active' : ''}`}>{t(item.title)}</span>
                        </Link>
                    ))}
                </div>
                <div className='select_languages'>
                </div>
                <SelectLanguage />
            </nav>
        </header>
    );
};

export default Header;
