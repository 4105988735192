import React from 'react';
import servicesData from '../../../data/services.json';
import './style.scss';

const Services = () => {

    return (
        <div className='home_block service_block'>
            <h1 className='block_title'>Our Services</h1>
            <div className='service_items'>
                {servicesData.map((item, idx) => (
                    <div key={idx} className='service_nth'>
                        <div className='service_icon'>
                            <img src={require('../../../assets/icons' + item.src)} alt='Service' />
                        </div>
                        <h2 className='service_title'>{item.title}</h2>
                        <h3 className='home_desc service_desc'>{item.description}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;