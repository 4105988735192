import {useEffect, useState} from "react";


export default function useWindowSize() {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleReSize = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        }
        window.addEventListener('resize', handleReSize);
        handleReSize();
        return () => window.removeEventListener('resize', handleReSize);

    }, [])

    return isMobile;

}