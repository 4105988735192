import React from 'react';
import './style.scss';

const LogoPayWay = () => {
    return (
        <div className='main_logo'>
            <svg
                width={81}
                height={50}
                viewBox="0 0 81 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 40.6622H23.0811V40.6621C26.6396 40.6621 29.5527 37.5027 29.5527 33.6421V29.4318H0V20.6776H29.5527V16.3579C29.5527 12.4971 26.6404 9.33781 23.0811 9.33781H15.555V0H26.7017C32.7407 0 37.6816 5.04239 37.6816 11.2055V38.7945C37.6816 44.9577 32.7407 50 26.7017 50H0V40.6622ZM80.3571 40.6622H57.276V40.6621C53.7175 40.6621 50.8044 37.5027 50.8044 33.6421V29.4318H80.3571V20.6776H50.8044V16.3579C50.8044 12.4971 53.7167 9.33781 57.276 9.33781H64.8021V0H53.6554C47.6164 0 42.6755 5.04239 42.6755 11.2055V38.7945C42.6755 44.9577 47.6164 50 53.6554 50H80.3571V40.6622Z"
                    fill="#2B4C75"
                />
            </svg>
        </div>

    );
};

export default LogoPayWay;
