import React from 'react';
import './style.scss';

const ContactUs = () => {
    return (
        <div className='main_page'>
            <h1>Contact Us</h1>
        </div>
    );
};

export default ContactUs;
