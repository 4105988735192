import React from 'react';
import './style.scss';

const Projects = () => {
    return (
        <div className='main_page'>
            <h1>Projects</h1>
        </div>
    );
};

export default Projects;
