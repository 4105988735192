import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationsList from '../data/translationsList.json'

const lng = localStorage.getItem('lang') || 'en';

export const loadResources = () => {
    let langData = {en: {}, hy: {}, ru: {}, fr: {}, it: {}}
    const keys = Object.keys(translationsList)
    keys.forEach(element => {
        langData.en[element] = translationsList[element].en
        langData.hy[element] = translationsList[element].hy
        langData.ru[element] = translationsList[element].ru
        langData.fr[element] = translationsList[element].fr
        langData.it[element] = translationsList[element].it
    });
    i18n.addResourceBundle('hy', 'translation', langData.hy)
    i18n.addResourceBundle('en', 'translation', langData.en)
    i18n.addResourceBundle('ru', 'translation', langData.ru)
    i18n.addResourceBundle('fr', 'translation', langData.fr)
    i18n.addResourceBundle('it', 'translation', langData.it)
    return translationsList
}

export default i18n.use(initReactI18next).init({
    resources: {},
    lng: lng,
    fallbackLng: 'en',
    react: {
        wait: true
    },
});

loadResources()