import React from 'react';
import './style.scss';
import phoneIcon from '../../../assets/icons/phone-icon.svg'
import emailIcon from '../../../assets/icons/email-icon.svg'
import fbIcon from '../../../assets/icons/fb-icon.svg'
import lnIcon from '../../../assets/icons/ln-icon.svg'

const Footer = () => {
    return (
        <footer>
            <div className='footer_block'>
                <div className='footer_address'>
                    <h1 className='title_footer'>Address</h1>
                    <h4 className='desc_footer'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet aliquam in
                        odio mattis ipsum ornare urna. Imperdiet at morbi arcu ornare vel vulputate magna. Volutpat
                        suspendisse orci in nisl blandit ut fermentum tellus. </h4>
                </div>
                <div className='footer_contact'>
                    <h1 className='title_footer'>Connect with Us</h1>
                    <div>
                        <div className='contact_info'>
                            <div className='info_nth'>
                                <img src={emailIcon} alt='Email'/>
                                <a href="mailto:info@esoft.am">info@esoft.am</a>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </footer>
    );
};

export default Footer;
